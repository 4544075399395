<template>
  <div class="wrapper-landing">
    <LandingHero
      title="¿Qué quieres para tu baby shower?"
      subtitle="Haz una lista con lo que te gusta y compártela de forma sencilla y elegante."
      origin="babyshower"
    >
      <template slot="cover">
        <source
          media="(max-width: 991px)"
          class=""
          srcset="../../assets/img/landings/cover-embarazada-posando-resp.jpg"
          alt=""
          loading="lazy"
        />
        <source
          media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
          class=""
          srcset="../../assets/img/landings/cover-embarazada-posando-@2x.jpg"
          alt=""
          loading="lazy"
        />
        <img
          class="ln-hero__inset"
          src="../../assets/img/landings/cover-embarazada-posando-resp.jpg"
          alt=""
          loading="lazy"
        />
      </template>
    </LandingHero>
    <main class="bg-cercles">
      <LandingAppRatings />
      <section class="bb-container">
        <LandingFeaturesSimple title="Prepárate para tu bebé con la ayuda de los tuyos"
        :use-carousel="false"
        >
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-box.svg" alt="" />
            </template>
            <template slot="title"> Elige cualquier cosa de cualquier tienda </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-hucha.svg" alt="" />
            </template>
            <template slot="title"> Gana un 5% de lo que gastéis </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-labels.svg" alt="" />
            </template>
            <template slot="title"> Crea fondos para reunir dinero </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-mobile.svg" alt="" />
            </template>
            <template slot="title"> Ten tu lista siempre en tu bolsillo </template>
          </LandingFeaturesSimpleItem>
        </LandingFeaturesSimple>
        <LandingReviews />
        <LandingHablan />
      </section>
    </main>

    <LandingComoList
      title="¿Cómo funciona?"
      subtitle="HelloBB es una lista de lo que quieres para tu bebé."
      :use-carousel="false"
    >
      <LandingComoListItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-click.svg" alt="" />
        </template>
        <template slot="titulo">Elige qué quieres</template>
        <template slot="descripcion">
          <p>
            Escoge el objeto que quieras de cualquier tienda online, copia el enlace en
            HelloBB y guárdalo.
          </p>
          <p>
            ¿Necesitas ideas o no sabes bien qué necesita? No hay problema, te
            recomendaremos las mejores cosas.
          </p>
          <p>Incluso puedes abrir fondos para reunir dinero para lo que quieras.</p>
        </template>
      </LandingComoListItem>

      <LandingComoListItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-share.svg" alt="" />
        </template>
        <template slot="titulo">Comparte tus deseos</template>
        <template slot="descripcion">
          <p>Cuando quieras, comparte tu lista con tus familiares y amigos.</p>
          <p>
            Enviales un enlace para que accedan desde cualquier sitio y sin registrarse a
            una bonita lista personalizada por ti.
          </p>
        </template>
      </LandingComoListItem>

      <LandingComoListItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-gift.svg" alt="" />
        </template>
        <template slot="titulo">Consigue los regalos</template>
        <template slot="descripcion">
          <p>
            Tus allegados podrán escoger el regalo de tu lista que más les guste y
            comprarlo. Te lo podrán mandar a ti o a ellos para dároslo en una visita.
          </p>
          <p>
            Para que nadie más lo compre, podrán marcar en la lista que ya está regalado.
          </p>
        </template>
      </LandingComoListItem>
    </LandingComoList>

    <LandingFooter />
  </div>
</template>

<script>
import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";
import LandingHero from "./components/LandingHero.vue";
import LandingAppRatings from "./components/LandingAppRatings.vue";
import LandingReviews from "./components/LandingReviews.vue";
import LandingHablan from "./components/LandingHablan.vue";
import LandingFooter from "./components/LandingFooter.vue";

import LandingComoList from "./components/LandingComoList.vue";

import LandingComoListItem from "./components/LandingComoListItem.vue";

export default {
  name: "LandingBabyShower",
  metaInfo() {
    return {
      title: "Babyshower | HelloBB",
      meta: [
        { name: "description", content: "Haz la lista de tu babyshower con HelloBB" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    LandingAppRatings,
    LandingHablan,
    LandingReviews,
    LandingFooter,
    LandingHero,
    LandingFeaturesSimple,
    LandingFeaturesSimpleItem,
    LandingComoList,
    LandingComoListItem,
  },
};
</script>
